import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHistogramBin, ILoadHistogramPayload, IUpdateHistogramPayload, IWorkoutHistogram } from './types';

export interface IHistogramState {
  loading: boolean;
  histogram: IWorkoutHistogram;
  users: {
    [userId: number]: IWorkoutHistogram;
  };
}

export const initialState: IHistogramState = {
  loading: true,
  histogram: {},
  users: {},
};

export const statePropName = 'histogram';

const histogramSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initHistogram: (state: IHistogramState) => {
      // Handle initialization if needed
    },
    histogramReady: (state: IHistogramState) => {
      state.loading = false;
    },
    loadHistogram: {
      reducer: (state: IHistogramState, action: PayloadAction<ILoadHistogramPayload>) => {
        const { workoutIds, userId } = action.payload;
        let clearedHistograms: IWorkoutHistogram = {
          ...state.histogram,
        };
        if (userId) {
          clearedHistograms = {
            ...state.users[userId],
          };
        }
        workoutIds.forEach((workoutId) => {
          delete clearedHistograms[workoutId];
        });
        if (!userId) {
          state.histogram = clearedHistograms;
        } else {
          state.users[userId] = clearedHistograms;
        }
        return state;
      },
      prepare: (workoutIds: number[], groupId: number | null, userId?: number) => {
        if (groupId === null) groupId = 0;
        return { payload: { workoutIds, groupId, userId } };
      },
    },
    updateHistogram: {
      reducer: (state: IHistogramState, action: PayloadAction<IUpdateHistogramPayload>) => {
        const { workoutId, histogram, userId } = action.payload;
        if (!userId) {
          const updatedHistogram = {
            ...state.histogram,
            [workoutId]: histogram,
          };
          return {
            ...state,
            histogram: updatedHistogram,
          };
        }
        const userHistograms = state.users[userId] || {};
        const updatedHistogram = {
          ...userHistograms,
          [workoutId]: histogram,
        };
        const updatedUsers = {
          ...state.users,
          [userId]: updatedHistogram,
        };
        return {
          ...state,
          users: updatedUsers,
        };
      },
      prepare: (workoutId: number, histogram: IHistogramBin[], userId?: number) => {
        return { payload: { workoutId, histogram, userId } };
      },
    },
  },
});

export const HistogramActions = histogramSlice.actions;
export default histogramSlice.reducer;

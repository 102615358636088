import { useDoubleClickProtection } from '@web_opxp/components';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import ObFlowService from './service';

interface INextObStepLinkProps {
  currentStepName?: string;
  language?: string;
  session?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export default function NextObStepLink({
  children,
  className,
  currentStepName,
  language,
  onClick,
  session,
}: PropsWithChildren<INextObStepLinkProps>) {
  const onClickHandler = useDoubleClickProtection(onClick);
  const nextObStep = ObFlowService.getNextObStep(currentStepName);
  const nextObUrl = ObFlowService.getNextObUrl(nextObStep, language, session);
  if (ObFlowService.isNextStepInCurrentApp(nextObStep)) {
    return (
      <Link to={nextObUrl} className={className} onClick={onClickHandler}>
        {children}
      </Link>
    );
  }
  return (
    <a href={nextObUrl} className={className} onClick={onClickHandler}>
      {children}
    </a>
  );
}

import * as React from 'react';

import Badge from '../badge';
import './UserAvatar.scss';

export interface IUserAvatarProps {
  className?: string;
  picture?: string;
  size?: 'big' | 'small';
  badge?: string | number;
}

export const UserAvatar = ({ picture, className, size, badge }: IUserAvatarProps) => {
  const sizeClass = size ? ` ${size}` : '';
  const customClass = className ? ` ${className}` : '';
  const avatarClass = `user-avatar${sizeClass}${customClass}`;
  const avatarStyles: React.CSSProperties = {};
  if (picture) {
    avatarStyles.backgroundImage = `url("${picture}")`;
  }
  const badgeItem = badge ? (
    <Badge color="green" className="user-menu-badge">
      {badge}
    </Badge>
  ) : null;
  return (
    <span className="avatar-container">
      <span className={avatarClass} style={avatarStyles} />
      <span className="menu-badge">{badgeItem}</span>
    </span>
  );
};

export default UserAvatar;

import { DefaultObFlow, OBFlowStepMap } from './constants';
import { IObFlowStep, IObFlowStepEntry, TObFlow } from './types';

export function parseObFlowStep(step: string) {
  const key = step[0];
  if (!key) {
    throw new Error('Invalid onboarding flow definition! Invalid key.');
  }
  const variant = Number(step[1] ?? '1');
  if (isNaN(variant)) {
    throw new Error('Invalid onboarding flow definition! Invalid variant.');
  }
  const obStep: IObFlowStepEntry = {
    key,
    variant,
  };
  return obStep;
}

export function parseObFlow(obFlow: string | null) {
  const obFlowStr = obFlow ?? DefaultObFlow;
  return obFlowStr.split('-').map((step: string) => parseObFlowStep(step));
}

export function getObFlow(searchParams?: URLSearchParams) {
  const obValueStr = searchParams?.get('ob_flow') ?? null;
  return parseObFlow(obValueStr);
}

export function findCurrentObStepIndex(currentStepName: string, flow: TObFlow) {
  if (!OBFlowStepMap[currentStepName]) {
    throw new Error('No such step in the onboarding flow map!');
  }
  const stepKey = OBFlowStepMap[currentStepName].key;
  return flow.findIndex((entry) => entry.key === stepKey);
}

/**
 * function returns variant of the current step defined in the flow
 * @param currentStepName name of the current step
 * @param searchParams current url search params
 */
export function getCurrentObStepVariant(currentStepName: string, searchParams?: URLSearchParams) {
  const flow = getObFlow(searchParams);
  const stepIndex = findCurrentObStepIndex(currentStepName, flow);
  if (stepIndex < 0) {
    console.warn('Current step not found in the flow!');
    // default variant is 1
    return 1;
  }
  return flow[stepIndex].variant;
}

export function getNextObStepEntry(currentStepName?: string, searchParams?: URLSearchParams) {
  const flow = getObFlow(searchParams);
  if (!currentStepName) {
    // its an entry point of the flow
    return flow[0];
  }
  const stepIndex = findCurrentObStepIndex(currentStepName, flow);
  if (stepIndex < 0) {
    return flow[0];
  }
  const nextStepIndex = stepIndex + 1;
  if (nextStepIndex === flow.length) {
    // its the last step of the flow
    return null;
  }
  return flow[nextStepIndex];
}

function getObStepFromMap(stepEntry: IObFlowStepEntry) {
  const { key, variant } = stepEntry;
  let flowStep = Object.values(OBFlowStepMap).find(
    // either key and variant are exactly same
    (step) => step.key === key && step.variant === variant
  );
  if (!flowStep) {
    flowStep = Object.values(OBFlowStepMap).find(
      // or we take default step with variant undefined (in case all variants share the url)
      (step) => step.key === key && step.variant === undefined
    );
  }
  if (!flowStep) {
    throw new Error('Next step not found in the onboarding flow map!');
  }
  return flowStep;
}

/**
 * function returns definition of the next step
 * consumer can decide how and where to redirect the user
 * @param currentStepName name of the current step
 * @param searchParams current url search params
 */
export function getNextObStep(currentStepName?: string, searchParams?: URLSearchParams) {
  const nextStepEntry = getNextObStepEntry(currentStepName, searchParams);
  if (nextStepEntry === null) {
    // final is step is the dashboard
    return null;
  }
  const nextObStep = getObStepFromMap(nextStepEntry);
  return nextObStep;
}

export function getReturnObStep(stepName: string, searchParams?: URLSearchParams): IObFlowStep | null {
  // we need to find next step which is not sso
  // to make it as a backUrl for sso link
  const step = getNextObStep(stepName, searchParams);
  if (step === null || step.app !== 'sso') {
    return step;
  }
  return getReturnObStep(step.name, searchParams);
}

export function getObFlowProgress(searchParams?: URLSearchParams) {
  const flow = getObFlow(searchParams);
  const progressItems: string[] = [];
  flow.forEach((flowEntry) => {
    const obStep = getObStepFromMap(flowEntry);
    if (obStep.progress) {
      progressItems.push(obStep.progress);
    }
  });
  return progressItems;
}

export function getCompletedProgress(currentStep: string, searchParams?: URLSearchParams) {
  const flow = getObFlow(searchParams);
  const items: string[] = [];
  for (const flowEntry of flow) {
    const obStep = getObStepFromMap(flowEntry);
    if (obStep.name === currentStep) {
      // if step is current return collected items
      return items;
    }
    if (obStep.progress) {
      items.push(obStep.progress);
    }
  }
  // fallback in case we did not found step in the flow
  return items;
}

export function getActiveProgress(currentStep: string, searchParams?: URLSearchParams) {
  const flow = getObFlow(searchParams);
  let found = false;
  for (const flowEntry of flow) {
    const obStep = getObStepFromMap(flowEntry);
    if (found && obStep.progress) {
      return obStep.progress;
    }
    if (obStep.name === currentStep) {
      if (obStep.progress) {
        return obStep.progress;
      }
      found = true;
    }
  }
}

export function isStepPresentInTheFlow(stepName: string, searchParams?: URLSearchParams) {
  const flow = getObFlow(searchParams);
  const stepIndex = findCurrentObStepIndex(stepName, flow);
  return stepIndex > -1;
}

export function getUnixTimestamp(jsTimestamp: number = Date.now()) {
  return Math.ceil(jsTimestamp / 1000);
}

export function unixToJsTimestamp(unixTimestamp = -1) {
  // timestamp === -1 means 'now' when sent to backend
  return unixTimestamp === -1 ? Date.now() : unixTimestamp * 1000;
}

export function unixTimestampToDate(unixTimestamp?: number) {
  return new Date(unixToJsTimestamp(unixTimestamp));
}

function isValidDate(date?: unknown): date is Date {
  return date instanceof Date && !isNaN(date.valueOf());
}

export function formatDate(
  date: number | string | Date,
  options: Intl.DateTimeFormatOptions = { dateStyle: 'long' },
  locale?: string
) {
  let dateObject: Date;
  try {
    dateObject = new Date(date);
    if (!isValidDate(dateObject)) {
      throw new Error('invalid date');
    }
    return new Intl.DateTimeFormat(locale, options).format(dateObject);
  } catch {
    // fallback
    dateObject = new Date(date);
    if (isValidDate(dateObject)) {
      try {
        return dateObject.toLocaleDateString(locale, options);
      } catch {
        return dateObject.toLocaleDateString();
      }
    }
    return date.toString();
  }
}

import { TObFlowStepMap } from './types';

export const DefaultObFlow = 'a-d-e-f-g-h-i';

// step with variant must be included in the map only if it has a different url/path
export const OBFlowStepMap: TObFlowStepMap = {
  welcome: {
    key: 'a',
    name: 'welcome',
    app: 'onboarding',
    path: '/welcome/video',
    progress: 'intro',
  },
  mascot: {
    key: 'a',
    variant: 2,
    name: 'welcome',
    app: 'onboarding',
    path: '/welcome/mascot',
    progress: 'intro',
  },
  questionaire: {
    key: 'b',
    name: 'questionaire',
    app: 'onboarding',
    path: '/questionnaire',
    progress: 'questionnaire',
  },
  quiz: {
    key: 'c',
    name: 'quiz',
    app: 'onboarding',
    path: '/quiz',
    progress: 'questionnaire',
  },
  quizAltOrder: {
    key: 'c',
    variant: 2,
    name: 'quiz',
    app: 'onboarding',
    path: '/quiz',
    progress: 'questionnaire',
  },
  signup: {
    key: 'd',
    name: 'signup',
    app: 'sso',
    path: '/opxp/signup',
  },
  signupMascot: {
    key: 'd',
    variant: 2,
    name: 'signup',
    app: 'sso',
    path: '/opxp/signup',
  },
  signupFun: {
    key: 'd',
    variant: 3,
    name: 'signup',
    app: 'sso',
    path: `/opxp/signup`,
  },
  signupLogo: {
    key: 'd',
    variant: 4,
    name: 'signup',
    app: 'sso',
    path: `/opxp/signup`,
  },
  signupOptIn: {
    key: 'e',
    name: 'signupOptIn',
    app: 'sso',
    path: `/opxp/signup/validation`,
  },
  assessment: {
    key: 'f',
    name: 'assessment',
    app: 'onboarding',
    path: `/assessment`,
    progress: 'assessment',
  },
  assessmentMascot: {
    key: 'f',
    variant: 2,
    name: 'assessment',
    app: 'onboarding',
    path: `/assessment`,
    progress: 'assessment',
  },
  evaluationIntro: {
    key: 'g',
    name: 'evaluationIntro',
    app: 'onboarding',
    path: `/evaluation/intro`,
    progress: 'evaluation',
  },
  // currently only intro page is separated from the evaluation page flow
  // the first step after intro must be the peergroup selection
  evaluation: {
    key: 'h',
    name: 'evaluation',
    app: 'onboarding',
    path: `/evaluation/peergroup`,
    progress: 'trainingplan',
  },
  evaluationMascot: {
    key: 'h',
    variant: 2,
    name: 'evaluation',
    app: 'onboarding',
    path: `/evaluation/peergroup`,
    progress: 'trainingplan',
  },
  subscribe: {
    key: 'i',
    name: 'subscribe',
    app: 'subscribe',
    path: '/',
  },
};

import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { NavBar2, NavItem2, NavMenu2 } from '../navBar2';
import UserAvatar from '../userAvatar';
import { IActionNavItem, IHrefNavItem, ILinkNavItem, IMenuNavItem, ISubmenuNavItem } from './types';

const renderLink = ({ link, label, onClick, className }: ILinkNavItem) => {
  return React.createElement(Link, { to: link, onClick, className }, label);
};
const renderHref = ({ link, label, onClick, className }: IHrefNavItem) => {
  return React.createElement('a', { href: link, onClick, className }, label);
};
const renderAction = ({ action, label, onAction }: IActionNavItem) => {
  return React.createElement('button', { onClick: () => onAction && onAction(action) }, label);
};

const renderSubmenu = ({ label, items, link, avatar, title, badge, onClick }: ISubmenuNavItem) => {
  const submenuItems = items.map((i, index) => renderMenuItem(i, index));
  const submenu = React.createElement(NavBar2, { vertical: true }, submenuItems);
  const menuLabel = React.createElement('span', { className: 'user-menu-label' }, label);
  const menuChild = link ? renderHref({ link, label, kind: 'href' }) : menuLabel;
  const menuTitle = title ? React.createElement('span', { className: 'user-menu-label' }, title) : null;
  let avatarItem;
  if (avatar === 'default') avatarItem = React.createElement(UserAvatar, { picture: undefined, size: 'small', badge });
  else if (avatar) avatarItem = React.createElement(UserAvatar, { picture: avatar, size: 'small', badge });
  const menuButton = React.createElement('button', { className: 'user-menu-item', onClick }, [
    avatarItem,
    menuChild,
    menuTitle,
  ]);
  return items.length > 0 ? React.createElement(NavMenu2, { content: submenu }, menuButton) : menuButton;
};

export const renderMenuItem = (item: IMenuNavItem, index: number, customClass?: string): ReactElement => {
  let content: ReactNode;
  switch (item.kind) {
    case 'href':
      content = renderHref(item);
      break;
    case 'link':
      content = renderLink(item);
      break;
    case 'menu':
      content = renderSubmenu(item);
      item.badge = undefined;
      break;
    case 'action':
      content = renderAction(item);
      break;
  }
  return React.createElement(
    NavItem2,
    { key: index, badge: item.badge, icon: item.icon, title: item.label ?? '', className: customClass },
    content
  );
};

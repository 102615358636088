import * as Sentry from '@sentry/react';

export const defaultIgnoredErrors: (string | RegExp)[] = [
  /chunk \d* failed/i,
  'installations/app-offline', // firebase error which is handled by fallback
  'Database deleted by request of the user', // see issue 607
  'AbortError', // see issue 708
];

function initSentry(isProduction: boolean, release: string, ignoreErrors?: (string | RegExp)[]) {
  const environment = isProduction ? 'production' : 'development';
  const domain = isProduction ? 'logging.neuronation.com' : 'sentry.dev.nn-services.de';
  const customIgnoreErrors = ignoreErrors ?? [];

  Sentry.init({
    // this dns relates to the current project for all opxp apps
    // if we will need to integrate also another project
    // we will need to extend this via arguments
    dsn: `https://7f8366669ad000e69c4f36ad74653ac8@${domain}/10`,
    integrations: [Sentry.browserTracingIntegration()],
    environment,
    tracesSampleRate: 1.0,
    release,
    // ignore chunk loading errors - nothing to do with them
    ignoreErrors: [...defaultIgnoredErrors, ...customIgnoreErrors],
  });
}

function captureMessage(message: string, props?: any) {
  Sentry.withScope((scope) => {
    if (props) {
      scope.setExtras(props);
    }
    Sentry.captureMessage(message);
  });
}

function captureException<T extends Error>(error: T, props?: any) {
  Sentry.withScope((scope) => {
    if (props) {
      scope.setExtras(props);
    }
    Sentry.captureException(error);
  });
}

const SentryService = {
  initSentry,
  captureException,
  captureMessage,
};

export default SentryService;
